import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const stockAdjustList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'reduce/list',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('Get stock adjust list err : ', err)

      return {}
    })

  return response
}

const stockAdjustGet = async id => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'reduce',
      param: id,
    })
    .then(res => (res.data || {}))
    .catch(err => {
      console.log('Get stock adjust err : ', err)

      return {}
    })

  return response
}

const drugList = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'reduce/drug',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('get drug equpmet list err : ', err)

      return {}
    })

  return response
}

const cartList = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'reduce/cartlist',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.log('get cart list err : ', err)

      return []
    })

  return response
}

const cartAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'reduce/cart',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('add cart  err : ', err)

      return {}
    })

  return response
}

const cartRemove = async id => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'reduce/cart',
      param: id,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('remove cart err : ', err)

      return {}
    })

  return response
}
const updateStock = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'reduce',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('update stock err : ', err)

      return {}
    })

  return response
}
export default {
  updateStock,
  stockAdjustList,
  stockAdjustGet,
  drugList,
  cartList,
  cartAdd,
  cartRemove,
}
