<template>
  <div>
    <v-card :loading="loading">
      <v-card-title class="px-2">
        <router-link
          class="font-weight-medium text-decoration-none me-2"
          :to="{ name: 'Stock-update-list'}"
        >
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                icon
                v-on="on"
              >
                <v-icon>{{ icons.mdiArrowLeftBold }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("back") }}</span>
          </v-tooltip>
        </router-link>
        {{ $t("requisition_drug_and_equipment") }} #{{ dataStockUpdate.transfer_id }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="d-none d-md-block"
          @click="print"
        >
          <v-icon class="me-1 ">
            {{ icons.mdiPrinter }}
          </v-icon> {{ $t("print_update_slip") }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          fab
          outlined
          icon
          @click="print"
        >
          <v-icon>
            {{ icons.mdiPrinter }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row
        class="px-5 mb-2"
        no-gutters
      >
        <v-col
          cols="12"
          md="6"
        >
          <p> <span class="font-weight-semibold"> {{ $t('cost_price') }}  : </span>{{ dataStockUpdate.transferorder_cost | formatPrice }}</p>
          <p> <span class="font-weight-semibold">{{ $t('updated_by') }} : </span>{{ dataStockUpdate.user_fullname }}</p>
          <p><span class="font-weight-semibold">{{ $t('pathfinder') }} : </span>{{ dataStockUpdate.transfer_label_name }}</p>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <p><span class="font-weight-semibold">{{ $t('stock_update_date') }} : </span><ConversDateTime :datetime="dataStockUpdate.transfer_create" /></p>
          <p><span class="font-weight-semibold">{{ $t('note') }} : </span>{{ dataStockUpdate.transfer_comment }}</p>
        </v-col>
      </v-row>
      <v-simple-table>
        <template v-slot:no-data-text>
          {{ $t('no_information') }}
        </template>
        <template v-slot:default>
          <thead>
            <tr>
              <th style="width:20px">
                #
              </th>
              <th
                class="text-left"
                style="width:500px"
              >
                {{ $t("list") }}
              </th>
              <th>
                {{ $t("expired_date") }}
              </th>
              <th class="text-right">
                {{ $t("amount") }}
              </th>

              <th>
                {{ $t("unit") }}
              </th>
              <th class="text-right">
                {{ $t("cost_price") }}
              </th>
              <th class="text-right">
                {{ $t("price") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in dataStockUpdate.transferorder"
              :key="index"
            >
              <td style="width:20px">
                {{ index + 1 }}
              </td>
              <td
                class="text-left"
                style="width:500px"
              >
                {{ item.transferorder_drug_name }} ({{
                  item.transferorder_drug_id
                }})
              </td>
              <td style="width:80px">
                <ConverseDate :date="item.transferorder_drugorder_expdate" />
              </td>
              <td
                style="width:80px"
                class="text-right"
              >
                {{ item.transferorder_amount | formatPrice }}
              </td>
              <td style="width:100px">
                {{ item.transferorder_drug_unit }}
              </td>
              <td
                class="text-right"
                style="width:100px"
              >
                {{ item.transferorder_cost | formatPrice }}
              </td>
              <td
                class="text-right"
                style="width:100px"
              >
                {{ item.transferorder_total | formatPrice }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
import { mdiPrinter, mdiArrowLeftBold } from '@mdi/js'
import router from '@/router'
import stockAdjust from '@/api/DrugManagement/stockAdjust'
import ConversDateTime from '@/components/basicComponents/ConversDateTime.vue'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import { formatPrice } from '@/plugins/filters'

export default {
  filters: {
    formatPrice,
  },
  components: {
    ConversDateTime,
    ConverseDate,
  },
  setup() {
    const dataStockUpdate = ref({})
    const loading = ref(true)

    const { stockAdjustGet } = stockAdjust
    stockAdjustGet(router.currentRoute.params.id).then(res => {
      dataStockUpdate.value = res
      loading.value = false
    })

    const print = () => {
      const routeData = router.resolve({
        name: 'printReduce',
        query: {
          id: String([router.currentRoute.params.id]),
        },
      })
      window.open(routeData.href, '_blank')
    }

    return {
      dataStockUpdate, icons: { mdiPrinter, mdiArrowLeftBold }, loading, print,
    }
  },
}
</script>
